<template>
  <div class="referral">
    <div class="content-referral">
      <div class="form-referral">
        <input type="text"
          v-if="question.referral.fields.name.active"
          class="input-name"
          v-model="formReferral.name"
          :placeholder="question.referral.fields.name.placeholder"
          @keyup="textValue('name')">
        <input type="email"
        v-if="question.referral.fields.email.active"
          class="input-email"
          v-model="formReferral.email"
          :placeholder="question.referral.fields.email.placeholder"
          @keyup="textValue('email')">
          <p v-if="alertEmail" class="alert-message">{{ alertMessage }}</p>
          <vue-tel-input
          v-if="question.referral.fields.phone.active"
            @input="textValue('phone')"
            class="input-phone"
            v-model="formReferral.phone"
            :autoFormat="false"
            :inputOptions="optionsPhone"
            :autoDefaultCountry="false"
            :validCharactersOnly="true"
            :defaultCountry="languageTel"
            ></vue-tel-input>
          <p v-if="alertPhone" class="alert-message">{{ alertMessagePhone }}</p>
        <button class="add-referral" v-if="showAddBtn" @click="setRefList()">
          {{question.referral.addLabel}}
        </button>
      </div>
    </div>
    <div class="list-referral" v-if="showRefList">
      <div class="card-referral" v-for="(item, idx) in listReferral" :key="idx">
        <p>
          <i class="material-icons icon-ref">person</i> {{item.name}}
        </p>
        <p>
          <i class="material-icons icon-ref">mail</i> {{item.email}}
        </p>
        <p>
          <i class="material-icons icon-ref">call</i> {{item.phone}}
        </p>
        <i class="material-icons icon-ref-delete" @click="removeRef(idx)">delete</i>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'appreferral',
  props: ['question'],
  components: {
    VueTelInput
  },
  data () {
    return {
      itemsRequired: [],
      lengthRequired: [],
      formReferral: {
        name: '',
        email: '',
        phone: ''
      },
      showAddBtn: false,
      listReferral: [],
      showRefList: false,
      alertEmail: false,
      alertPhone: false,
      alertMessage: this.$i18n.t('referral.alert_email'),
      alertMessagePhone: this.$i18n.t('referral.alert_phone'),
      optionsPhone: {
        placeholder: this.question.referral.fields.phone.placeholder ? this.question.referral.fields.phone.placeholder : 'Placeholder',
        autofocus: true,
        required: this.question.required,
        showDialCode: true,
        type: 'tel'
      }
    }
  },
  created () {
    // forçando o required da question false para poder validar o required de cada campo do referral
    this.question.required = true
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit()
      }
    })

    this.checkFields()
  },
  computed: {
    mountedSurvey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    languageTel () {
      let languageTel
      if (this.question.language === 'pt-br') {
        languageTel = 'BR'
      } else if (this.question.language === 'en-us') {
        languageTel = 'US'
      } else {
        languageTel = 'ES'
      }
      return languageTel
    }
  },
  methods: {
    checkFields () {
      const fields = Object.entries(this.question.referral.fields)

      fields.forEach(item => {
        if (item[1].required && item[1].active) {
          this.itemsRequired.push({ name: item[0], done: false })
          const inputItem = document.querySelector(`.input-${item[0]}`)
          if (inputItem) {
            inputItem.setAttribute('style', 'border: 1px solid #ff00004d !important')
          }
        }
        if (this.itemsRequired.length === 0) {
          this.$root.$emit('show-btn', true)
          this.showAddBtn = true
        } else if (this.listReferral.length === 0) {
          this.$root.$emit('show-btn', false)
          this.showAddBtn = false
        }
      })
    },
    textValue (type) {
      if (this.itemsRequired.length > 0 && this.listReferral.length === 0) {
        if (type === 'name') {
          this.itemsRequired.forEach((item, idx) => {
            if (item.name === type) {
              const input = document.querySelector(`.input-${type}`)
              if (this.formReferral[item.name].length > 0) {
                input.style.borderColor = '#cacaca'
                this.itemsRequired[idx].done = true
              }
              if (this.formReferral[item.name].length <= 0) {
                this.itemsRequired[idx].done = false
                input.style.borderColor = '#ff00004d'
              }
            }
          })
        }
        if (type === 'email') {
          this.itemsRequired.forEach((item, idx) => {
            if (item.name === type) {
              const input = document.querySelector(`.input-${type}`)
              if (this.formReferral[item.name].length > 0) {
                this.itemsRequired[idx].done = true
                input.style.borderColor = '#cacaca'
                this.alertEmail = false
              }
              if (this.formReferral[item.name].length <= 0) {
                this.itemsRequired[idx].done = false
                input.style.borderColor = '#ff00004d'
              }
            }
          })
        }
        if (type === 'phone') {
          this.itemsRequired.forEach((item, idx) => {
            if (item.name === type) {
              const input = document.querySelector(`.input-${type}`)
              if (this.formReferral[item.name].length > 0) {
                this.itemsRequired[idx].done = true
                input.setAttribute('style', 'border: 1px solid #cacaca !important')
              }
              if (this.formReferral[item.name].length <= 0) {
                this.itemsRequired[idx].done = false
                input.setAttribute('style', 'border: 1px solid #ff00004d !important')
              }
            }
          })
        }
        const items = this.itemsRequired.map((e, i) => { return e })
        const found = items.find(e => !e.done)
        if (!found) {
          this.$root.$emit('show-btn', true)
          this.showAddBtn = true
        } else {
          this.$root.$emit('show-btn', false)
          this.showAddBtn = false
        }
      } else {
        this.$root.$emit('show-btn', true)
        this.showAddBtn = true
      }
    },
    setRefList () {
      if (this.question.referral.fields.email.required && this.formReferral.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
        this.alertEmail = true
        return
      }
      this.listReferral.push(this.formReferral)
      this.formReferral = {
        name: '',
        email: '',
        phone: ''
      }
      this.showRefList = true
      this.$root.$emit('show-btn', true)
      this.showAddBtn = false
      this.alertEmail = false
      this.checkFields()
    },
    before () {
      const beforeQuestion = this.$store.getters['question/getQuestion']
      beforeQuestion.pop()
      this.$store.commit('question/setQuestion', beforeQuestion)
    },
    clean () {
      if (!this.formReferral.email) { this.alertEmail = false }
    },
    async submit () {
      let data = {}
      const macthEmail = this.formReferral.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      const macthPhone = Number(this.formReferral.phone)
      if (this.itemsRequired.length > 0) {
        if (this.question.referral.fields.email.required && this.formReferral.email.length > 0) {
          if (!macthEmail) {
            this.alertEmail = true
            setTimeout(() => { this.alertEmail = false }, 3000)
            this.$root.$emit('show-btn', false)
            return
          }
        }
        if (this.question.referral.fields.phone.required && this.formReferral.phone.length > 0) {
          if (Number.isNaN(macthPhone)) {
            this.alertPhone = true
            setTimeout(() => { this.alertPhone = false }, 3000)
            this.$root.$emit('show-btn', false)
            return
          }
        }
        if (this.formReferral.email.length > 0 || this.formReferral.name.length > 0 || this.formReferral.phone.length > 0) {
          this.listReferral.push(this.formReferral)
        }
        this.formReferral = {
          name: '',
          email: '',
          phone: ''
        }
      } else {
        if (this.formReferral.email.length > 0) {
          if (!macthEmail) {
            this.alertEmail = true
            setTimeout(() => { this.alertEmail = false }, 3000)
            this.$root.$emit('show-btn', false)
            return
          }
        }
        if (this.formReferral.phone.length > 0) {
          if (Number.isNaN(macthPhone)) {
            this.alertPhone = true
            setTimeout(() => { this.alertPhone = false }, 3000)
            this.$root.$emit('show-btn', false)
            return
          }
        }
        if (this.formReferral.email.length > 0 && this.formReferral.name.length > 0 && this.formReferral.phone.length > 0) {
          this.listReferral.push(this.formReferral)
        }
        this.formReferral = {
          name: '',
          email: '',
          phone: ''
        }
      }
      data = { referralEntries: this.listReferral, rating: 100 }
      this.submitEvent(data)
      this.checkFields()
    },
    submitEvent (data) {
      this.alertEmail = false
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
    },
    removeRef (index) {
      this.listReferral.splice(index, 1)
      if (this.listReferral.length === 0) {
        this.$root.$emit('show-btn', false)
        this.showAddBtn = false
        this.showRefList = false
        this.checkFields()
      }
    }
  }
}
</script>
<style lang="scss">
.referral {
  width: 100%;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .content-referral {
    width: 100%;
    min-height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .form-referral {
      width: 65%;
      min-height: 370px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .input-name, .input-email, .input-phone {
        background: #ffffff47;
        border: 1px solid #cacaca;
        width: 100%;
        height: 45px;
        margin-bottom: 10px;
        outline: none;
        padding-left: 5px;
        font-size: 16px;
        color: #000000;
        border-radius: 3px;
      }
      .input-name::placeholder, .input-email::placeholder, .input-phone::placeholder {
        color: #959595;
      }
      .vue-tel-input {
        width: 100%;
        height: 45px !important;
        box-shadow: none !important;
        .vti__input {
          font-size: 16px;
        }
        .vti__input:not(input.field) {
          -webkit-user-select: text;
          -khtml-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
        }
      }
      .add-referral {
        padding: 7px 5px;
        border-radius: 3px;
        margin-top: 10px;
        font-size: 14px;
        background: var(--headerBg);
        color: var(--headerColor);
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
          -60deg,
          transparent, transparent 40%,
          #ffffff44 40%, #ffffff44 60%,
          transparent 60%, transparent 100%
        );
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position-x: 150%;
      }
      .add-referral:hover {
        background-position-x: -150%;
        transition: background-position-x 1s;
        cursor: pointer;
      }
      .alert-message {
        width: 100%;
        padding-left: 5px;
        color: #D91212ed;
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
  }
  .list-referral {
    box-sizing: border-box;
    border-top: 1px solid #cacaca;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .card-referral {
      position: relative;
      box-sizing: border-box;
      background: #f0f0f0;
      border-radius: 3px;
      border: 1px solid #cacaca;
      box-shadow: 0 8px 10px 0 rgba(255, 255, 255, 0.030);
      width: 45%;
      margin: 5px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      p {
        font-size: 13px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-ref {
          font-size: 20px;
          margin-right: 10px;
          color: #8a8a8a;
        }
      }
      .icon-ref-delete {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 20px;
        color: #8a8a8a;
        cursor: pointer;
      }
      .icon-ref-delete:hover {
        transform: scale(1.03);
      }
    }
  }
}

// responsivity to mobile
@media only screen and (max-width: 850px) {
  .referral {
    height: 100% !important;
    .content-referral {
      min-height: 100% !important;
      padding-top: 10px;
      .form-referral {
        min-height: 100% !important;
        width: 95% !important;
        .input-name, .input-email {
          height: 45px !important;
          font-size: 16px;
        }
        .vue-tel-input {
          height: 45px;
          box-shadow: none !important;
          .vti__input {
            font-size: 16px;
          }
        }
        .add-referral {
          margin-top: 20px;
          padding: 10px 7px;
        }
      }
    }
    .list-referral {
      .card-referral {
        width: 95%;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        p {
          font-size: 17px;
          .icon-ref {
            font-size: 25px;
          }
        }
        .icon-ref-delete {
          font-size: 25px;
        }
      }
    }
  }
}
</style>
